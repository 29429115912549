<template>
    <div class="pl-8 pr-8">
      <div class="mb-xl-margin-file-sign">
        <v-row class="mt-10">
          <v-col class="text-left" cols="6">
            <h1 class="reference__title">Solicitud en curso</h1>
          </v-col>
        </v-row>
        <v-card outlined class="reference__card px-8 mt-8" elevation="0">
          <!-- Filtros -->
          <v-row class="mt-2">
            <v-col cols="6">
              <v-autocomplete
                :items="costCenters"
                label="Centro de costos"
                v-model="costCentersId"
                item-text="centro_costo"
                item-value="centro_costo"
                multiple
                @change="handleCostCenterChange"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="subCostCenters"
                label="Sub Centro de Costos"
                v-model="subCostCentersId"
                item-text="subcentro_costo"
                item-value="subcentro_costo"
                multiple
                @change="handleSubCostCenterChange"
                :disabled="subCostCenters.length === 0"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="campaign"
                label="Campaña"
                v-model="campaignId"
                item-text="campania"
                item-value="campania"
                multiple
                @change="handleCampaing"
                :disabled="campaign.length === 0"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="pl-0 pr-0 mb-5" cols="12">
              <v-data-table
                :loading="loading.table"
                :items-per-page="15"
                :headers="headers"
                :items="listProcessDisciplinary"
                :footer-props="{ itemsPerPageOptions: [5, 10, 15] }"
                class="elevation-1 candidate-list__table"
              >
                <template v-slot:no-results>
                  <v-alert
                    text
                    dense
                    type="info"
                    class="mt-3 forms__alert-completed"
                  >
                    <b>No se encontraron resultados.</b>
                  </v-alert>
                </template>
                <template class="pl-0 pr-0" v-slot:no-data>
                  <v-alert
                    text
                    dense
                    type="info"
                    class="mt-3 forms__alert-completed"
                  >
                    <b>No se encontró información.</b>
                  </v-alert>
                </template>
                <template v-slot:progress>
                  <v-progress-linear
                    color="#214484"
                    :height="5"
                    indeterminate
                    stream
                  ></v-progress-linear>
                </template>
  
                <template v-slot:item.status_id="{ item }">
                  <v-chip
                    v-if="item.status_id"
                    :color="getColorStatus(item.status_id)"
                    dark
                    small
                  >
                    {{ getNameStatus(item.status_id) }}
                  </v-chip>
                </template>
  
                <template v-slot:item.actions="{ item }">
                  <v-tooltip max-width="300" color="#000" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="goDeteils(item)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="blue"
                        fab
                      >
                        <v-icon> mdi-eye-outline </v-icon>
                      </v-btn>
                    </template>
                    <span> Detalle de la solicitud </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-dialog v-model="openConfirm" width="410" persistent>
        <v-card style="border-radius: 15px">
          <v-card-title class="pt-6">
            <v-layout justify-center>
              <img
                class="ml-6"
                width="80"
                height="80"
                src="../../../assets/icon/alert.svg"
              />
            </v-layout>
            <v-btn
              @click="openConfirm = false"
              color="#466BE3"
              icon
              x-small
              style="display: flex; left: 18px; bottom: 45px"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p class="document-managmet1__txt-dialog-title">¿Está seguro?</p>
            <p class="document-managmet1__txt-dialog-sms pb-0 mb-0">
              Que desea eliminar la solicitud de desarrollo?
            </p>
  
            <!-- Agregar cuadro de texto para el motivo -->
            <v-textarea
              v-model="deleteReason"
              label="Motivo de eliminación"
              outlined
              rows="3"
              dense
              clearable
              class="mt-5"
            ></v-textarea>
            El motivo de eliminación debe ser mayor a 20 caracteres.
          </v-card-text>
          <v-card-actions class="pb-8">
            <v-row>
              <v-col cols="6">
                <v-btn
                  block
                  color="#466BE3"
                  dark
                  rounded
                  @click="openConfirm = false"
                  outlined
                >
                  CERRAR
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  color="#466BE3"
                  dark
                  rounded
                  @click="deleteDoc()"
                  :disabled="deleteReason.length < 20"
                  :style="{
                    background: deleteReason.length <= 20 ? '#D3D3D3' : '#466BE3',
                  }"
                >
                  Eliminar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <Alert
        :open="message.dialog"
        :text="message.sms"
        :title="message.title"
        :type="message.type"
        :redirect="message.redirect"
        @close="message.dialog = false"
      >
      </Alert>
    </div>
  </template>
  <script>
  import color from "@/mixins/color";
  import Api from "@/utils/api";
  import Alert from "@/components/Alert.vue";
  export default {
    components: {
      Alert,
    },
    mixins: [color],
    data() {
      return {
        deleteReason: "",
        openConfirm: false,
        currentUser: [],
        listProcessDisciplinary: [],
        search: 0,
        loading: {
          table: false,
        },
        IDSearch: ["8", "12", "4", "10", "11"],
        headers: [
          {
            text: "ID de Solicitud",
            value: "id",
            align: "center",
            class: "candidate-list__header",
            sortable: false,
          },
          {
            text: "Nombre",
            value: "full_name",
            align: "center",
            class: "candidate-list__header",
            sortable: false,
          },
          {
            text: "Fecha de creación",
            value: "created_at",
            align: "center",
            class: "candidate-list__header",
          },
          {
            text: "Fecha de entrega",
            value: "fecha_estimada_req",
            align: "center",
            class: "candidate-list__header",
          },
          {
            text: "Centro de costo",
            value: "centro_costo",
            align: "center",
            class: "candidate-list__header",
            sortable: false,
          },
          {
            text: "Campaña",
            value: "campania",
            align: "center",
            class: "candidate-list__header",
            sortable: false,
          },
          {
            text: "Status",
            value: "status_id",
            align: "center",
            class: "candidate-list__header",
            sortable: false,
          },
          {
            text: "Acciones",
            value: "actions",
            align: "center",
            class: "candidate-list__header",
            sortable: false,
          },
        ],
        modal: {
          info: false,
        },
        message: {
          dialog: false,
          title: "",
          sms: "",
          type: "",
          redirect: "",
        },
        loading: {
          table: false,
        },
        costCenters: [],
        costCentersId: [],
        subCostCenters: [],
        subCostCentersId: [],
        campaign: [],
        campaignId: [],
      };
    },
    methods: {
      openModal(data) {
        this.openConfirm = true;
      },
      goDeteils(data) {
        localStorage.setItem("infoDevelopmentProcess", JSON.stringify(data));
        this.$router.push({
          name: "detailDevelopment",
          params: { id: data.id },
          query: { status_id: data.status_id },
        });
      },
      getToken() {
        var token = localStorage.getItem("token");
        var tokenb64 = localStorage.getItem("tokenB64");
  
        this.token = tokenb64;
        var div = token.split(".");
        if (div.length >= 2) {
          this.infoToken = JSON.parse(atob(div[1]));
          var nameList = this.infoToken.fullName.split(" ");
  
          if (nameList.length > 1) {
            if (nameList[0] != "") {
              this.name = nameList[0];
            } else {
              this.name = nameList[1];
            }
          } else {
            this.name = "";
          }
          // console.log(JSON.parse(atob(div[1])));
        } else {
          this.infoToken = {
            document: "",
          };
        }
      },
      searchData() {
        var data = {
          centro_costo: this.mapingDataCc(),
          subcentro_costo: this.mapingDataScc(),
          campania: this.mapingDataCampaing(),
          user_id: this.currentUser.id,
          status_id: this.IDSearch,
        };
        this.loadingAll = true;
        Api.newDevelopment()
          .listDevelopments(this.token, data)
          .then((res) => {
            this.listProcessDisciplinary = res.data.data;
          })
          .catch((error) => {})
          .finally(() => {
            this.loadingAll = false;
          });
      },
      listCostCenter() {
        var data = {
          status_id: this.IDSearch,
          user_id: this.currentUser.id,
        };
        Api.newDevelopment()
          .listOwnerCostCenter(this.token, data)
          .then((res) => {
            this.costCenters = [{ centro_costo: "Todos" }, ...res.data.data];
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.searchData();
          });
      },
      listSubCostCenter() {
        var data = {
          centro_costo: this.mapingDataCc(),
          status_id: this.IDSearch,
          user_id: this.currentUser.id,
        };
        if (this.costCentersId.length === 0) {
          this.subCostCenters = [];
          this.campaign = [];
          this.searchData();
        } else {
          Api.newDevelopment()
            .listOwnerSubCostCenter(this.token, data)
            .then((res) => {
              this.subCostCenters = [
                { subcentro_costo: "Todos" },
                ...res.data.data,
              ];
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.searchData();
            });
        }
      },
      listCampaign() {
        if (this.subCostCentersId.length == 0) {
          this.campaign = [];
          this.searchData();
        } else {
          var data = {
            centro_costo: this.mapingDataCc(),
            subcentro_costo: this.mapingDataScc(),
            user_id: this.currentUser.id,
            status_id: this.IDSearch,
          };
          Api.newDevelopment()
            .listOwnerCampaign(this.token, data)
            .then((res) => {
              this.campaign = res.data.data.filter(
                (item) => item.campania !== null
              );
              if (
                !this.campaignId.includes("Todos") &&
                this.campaign.length != 0
              ) {
                this.campaign.unshift({ campania: "Todos" });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.searchData();
            });
        }
      },
      user() {
        let currentUser = this.$store.getters.getUser;
        this.currentUser = currentUser;
  
        this.searchData();
      },
      handleCostCenterChange() {
        if (this.costCentersId.includes("Todos")) {
          this.costCentersId = ["Todos"];
        }
        this.listSubCostCenter();
      },
      handleSubCostCenterChange() {
        if (this.subCostCentersId.includes("Todos")) {
          this.subCostCentersId = ["Todos"];
        }
        this.listCampaign();
      },
      handleCampaing() {
        if (this.campaignId.includes("Todos")) {
          this.campaignId = ["Todos"];
        }
        this.searchData();
      },
      mapingDataCc() {
        const isTodosSelected = this.costCentersId.includes("Todos");
        if (isTodosSelected) {
          return this.costCenters
            .map((costCenter) => costCenter.centro_costo)
            .filter((costCenter) => costCenter !== "Todos");
        } else {
          return this.costCentersId;
        }
      },
      mapingDataScc() {
        const isTodosSelected = this.subCostCentersId.includes("Todos");
        if (isTodosSelected) {
          return this.subCostCenters
            .map((subCostCenter) => subCostCenter.subcentro_costo)
            .filter((subCostCenter) => subCostCenter !== "Todos");
        } else {
          return this.subCostCentersId;
        }
      },
      mapingDataCampaing() {
        const isTodosSelected = this.campaignId.includes("Todos");
        if (isTodosSelected) {
          return this.campaign.map((campaign) => campaign.campaign);
        } else {
          return this.campaignId;
        }
      },
      searchOwner(item) {
        return item.user_id === this.currentUser.id;
      },
      getNameStatus(status) {
        switch (status) {
          case 8:
            return "Solicitud en curso";
          case 12:
            return "Entrega a pruebas";
          case 4:
            return "Entrega a producción";
          case 10:
            return "Rechazo de pruebas";
          case 11:
            return " Aceptación de pruebas";
          default:
            return "Desconocido";
        }
      },
      getColorStatus(status) {
        switch (status) {
          case 8:
            return "green";
          case 12:
            return "darkpurple";
          case 4:
            return "green";
          case 10:
            return "red";
          case 11:
            return "blue";
          default:
            return "Desconocido";
        }
      },
    },
    created() {
      this.getToken();
      setTimeout(() => {
        this.user();
        this.listCostCenter();
      }, 3000);
    },
  };
  </script>
  <style>
  @import "../../../assets/css/main.less";
  
  .document-managmet1__txt-dialog-title {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #353535;
  }
  .document-managmet1__txt-dialog-sms {
    font-family: "RobotoMedium";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #898989;
  }
  
  .reference__table .v-data-table-header {
    font-family: "RobotoRegular";
    background-color: #f2f4ff;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px;
    color: #353535 I !important;
  }
  
  tr,
  th,
  span .reference__table .text-start .sortable {
    font-size: 16px !important;
  }
  
  .reference__card {
    border-radius: 15px !important;
  }
  
  .reference__title {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    color: #353535;
  }
  
  .references__header {
    color: #353535 !important;
  }
  </style>
  